import React from 'react';

const WefeIndexHeader = () => (
    <header className="text-center my-4 wefe-index-header">
        <h1>WEFE Index</h1>
        <p>Harmony in Action</p>
    </header>
);

export default WefeIndexHeader;
