import React, { useState } from 'react';
import WefeIndexHeader from '../../Components/WefeIndex/WefeIndexHeader';
import WefeIndexFilters from '../../Components/WefeIndex/WefeIndexFilters';
import WefeIndexCard from '../../Components/WefeIndex/WefeIndexCard';
import data from '../../data/wefeIndeaxdata';
import './WefeIndex.css';

const WefeIndex = () => {
    const [filter, setFilter] = useState({ country: '', year: '', category: 'all',flag:'' });
    const [activeTab, setActiveTab] = useState('all');

    const handleFilterChange = (type, value) => {
        setFilter({ ...filter, [type]: value });
    };

    const filteredData = data.filter(item => {
        const countryMatch = item.country.toLowerCase().includes((filter.country ?? '').toLowerCase());
        const yearMatch = item.year ? item.year.toString().includes((filter.year ?? '').toString()) : true;
        const categoryMatch = filter.category === 'all' || item.category === filter.category;
        return countryMatch && yearMatch && categoryMatch;
    });

    return (
        <div className="container wefe-index">
            <WefeIndexHeader />
            <WefeIndexFilters handleFilterChange={handleFilterChange} activeTab={activeTab} setActiveTab={setActiveTab} />
            <div className="row">
                {filteredData.map((item, index) => (
                    <div className="col-md-4" key={index}>
                        <WefeIndexCard country={item.country} rank={item.rank} indicators={item.indicators} flag={item.flag} />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default WefeIndex;
