// src/Pages/StoryDetailedView.js
import React from 'react';
import { useParams } from 'react-router-dom';
import storiesData from '../data/storiesData'; // Import the data
import SliderWithThumbnails from '../Components/SliderWithThumbnails';
const StoryDetailedView = () => {
    const { id } = useParams();
    const section = storiesData.find(item => item.id === parseInt(id));

    if (!section) {
        return <div className="container">Section not found</div>;
    }
    return (
        <div className="container story-detailed-view">
            <div className="row">
                <div className="col-lg-8 col-md-7">
                    <h1>{section.title}</h1>
                    {/* Slider with Thumbnails */}
                    <div className="mb-4">
                        <SliderWithThumbnails images={section.thumbnails} />
                    </div>
                    {/* Story Information */}
                    <div className="mb-4">
                        {Object.entries(section.storyInfo).map(([title, value], index) => (
                            <div key={index} className="row mb-2">
                                <div className="col-6">{title}:</div>
                                <div className="col-6">{value}</div>
                            </div>
                        ))}
                    </div>
                    {/* Story Background */}
                    <div className="mb-4">
                        <h3>Story Background</h3>
                        <p>{section.storyBackground}</p>
                    </div>
                    {/* Story Description */}
                    <div className="mb-4">
                        <h3>Story Description</h3>
                        <p>{section.storyDescription}</p>
                    </div>
                    {/* Approach & Main Components */}
                    <div className="mb-4">
                        <h3>Approach & Main Components</h3>
                        <p>{section.storyDescription}</p>
                    </div>
                    {/* Description of Sustainability Status of Story */}
                    <div className="mb-4">
                        <h3>Description of Sustainability Status of Story</h3>
                        <p>{section.storyDescription}</p>
                    </div>
                    {/* Additional Information */}
                    <div className="mb-4">
                        <h3>Additional Information</h3>
                        <p>{section.storyDescription}</p>
                    </div>
                    {/* Achievements & Impacts */}
                    <div className="mb-4">
                        <h3>Achievements & Impacts</h3>
                        <p>{section.storyDescription}</p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-5 profile-section">
                    <div className="profile-section-first-part">
                    {/* Profile Info */}
                    <div className="mb-4">
                        <img src={section.profilePic} alt={section.profileName} className="rounded-circle" style={{ width: '100px' }} />
                        <h3>{section.profileName}</h3>
                        <button className="btn btn-primary story-btn" onClick={() => window.location.href = section.authorProfileLink}>Author Profile</button>
                    </div>
                    {/* Published Date */}
                    <div className="mb-4">
                        <h3>Published On</h3>
                        <p>{section.date}</p>
                    </div>
                    {/* Links */}
                    <div className="mb-4">
                        <button className="btn btn-primary story-btn" onClick={() => window.location.href = section.storyLink}>Link to Story</button>
                        <button className="btn btn-primary story-btn" onClick={() => window.location.href = section.pdfLink}>Download PDF</button>
                    </div>
                    {/* Country Info */}
                    <div className="mb-4">
                        <h3>Country / Region</h3>
                       <p>{section.country.name}</p>
                        <p> {section.country.location}</p>
                        <div dangerouslySetInnerHTML={{ __html: section.country.mapLink }} />
                    </div>
                    </div>
                    {/* Implementing Partners */}
                    <div>
                        <h3>Implementing Partners</h3>
                        {section.partners.map((partner, index) => (
                            <div key={index} className="mb-2">
                                <img src={partner.image} alt={partner.name} style={{ width: '50px', marginRight: '10px' }} />
                                <strong>{partner.name}</strong>
                                <p>{partner.sector}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};
export default StoryDetailedView;