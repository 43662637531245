// src/components/Banner.js
import React from 'react';
import './Banner.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
const Banner = ({ title,description }) => {
    return (
        <div className="banner">
            <h1>{title}</h1>
            <p>{description}</p>
            <button className="add-button">
                <FontAwesomeIcon icon={faPlus} style={{ marginRight: '0.5rem' }} />
                Add New
            </button>
        </div>
    );
};

export default Banner;
