// LoginMenu.js
import React from 'react';
import { Link } from 'react-router-dom';
import './LoginMenu.css'; // Create and style this file as needed

const LoginMenu = ({ isVisible }) => {
    return (
        isVisible && (
            <div className="login-menu">
                <ul>
                    <li><Link to="/login">Login</Link></li>
                    <li><Link to="/register">Register</Link></li>
                </ul>
            </div>
        )
    );
};

export default LoginMenu;
