import React, { useEffect, useState } from 'react';
import Swiper from 'swiper/bundle';
import 'swiper/swiper-bundle.css';
import './PartnerSection.css';

const PartnerSection = () => {
    const [partners, setPartners] = useState([]);

    useEffect(() => {
        const fetchPartners = async () => {
            try {
                const response = await fetch('https://apis-wefe.haka-jo.com/website/partners');
                const data = await response.json();
                if (data.error_flag === 0) {
                    setPartners(data.result.partners);
                } else {
                    console.error('Failed to fetch partners:', data.message);
                }
            } catch (error) {
                console.error('Error fetching partners:', error);
            }
        };

        fetchPartners();

        const swiper = new Swiper('.mySwiper', {
            slidesPerView: 2,
            spaceBetween: 30,
            freeMode: true,
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
            breakpoints: {
                768: {
                    slidesPerView: 5,
                },
                320: {
                    slidesPerView: 2,
                },
            },
        });

        // Cleanup on unmount
        return () => {
            if (swiper) swiper.destroy();
        };
    }, []);

    return (
        <div className="brand-area pt-90">
            <div className="container">
                <div className="about-title">
                    <h2>Our Partners</h2>
                    <p>Continues trust worth holding on to</p>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="swiper mySwiper">
                            <div className="swiper-wrapper">
                                {partners.map((partner) => (
                                    <div key={partner.id} className="swiper-slide">
                                        <img src={partner.image} alt={partner.alt_name} />
                                    </div>
                                ))}
                            </div>
                            <div className="swiper-pagination"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PartnerSection;
