// src/components/Filters.js
import React from 'react';
import './Filters.css';
import regionIcon from '../Assets/Images/region-icon.png'; // Replace with your actual icon paths
import yearIcon from '../Assets/Images/year-icon.png';
import keywordsIcon from '../Assets/Images/keywords-icon.png';
import sectorIcon from '../Assets/Images/sector-icon.png';
const Filters = ({ filters, onFilterChange }) => {
    return (
        <div className="filters container">
            <div className="input-group">
                <select className="select-icon" onChange={(e) => onFilterChange('region', e.target.value)}>
                    <option value="">Select Region</option>
                    {/* Add options dynamically */}
                </select>
                <img src={regionIcon} alt="Region Icon" className="icon" />
            </div>
            <div className="input-group">
                <select className="select-icon" onChange={(e) => onFilterChange('year', e.target.value)}>
                    <option value="">Select Year</option>
                    {/* Add options dynamically */}
                </select>
                <img src={yearIcon} alt="Year Icon" className="icon" />
            </div>
            <div className="input-group">
                <input type="text" placeholder="Keywords" onChange={(e) => onFilterChange('keywords', e.target.value)} />
                <img src={keywordsIcon} alt="Keywords Icon" className="icon" />
            </div>
            <div className="input-group">
                <select className="select-icon" onChange={(e) => onFilterChange('sector', e.target.value)}>
                    <option value="">Select Sector</option>
                    {/* Add options dynamically */}
                </select>
                <img src={sectorIcon} alt="Sector Icon" className="icon" />
            </div>
        </div>
    );
};

export default Filters;