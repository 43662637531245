import waterIcon from '../Assets/Images/water-icon.svg'
import energyIcon from '../Assets/Images/energy-icon.svg'
import foodIcon from '../Assets/Images/food-icon.svg'
import ecosystemIcon from '../Assets/Images/ecosystem-icon.svg'
import jordanFlag from '../Assets/Images/jordan-flag.svg'
const data = [
  {
    country: 'Jordan',
    flag: jordanFlag,
    rank: 113,
    year: 2023,
    category: 'water',
    indicators: [
      { name: 'Water', value: 35, icon: waterIcon },
      { name: 'Energy', value: 74, icon: energyIcon },
      { name: 'Food', value: 61, icon: foodIcon },
      { name: 'Ecosystem', value: 54, icon: ecosystemIcon }
    ]
  },
  {
    country: 'Jordan',
    flag: jordanFlag,
    rank: 113,
    year: 2023,
    category: 'water',
    indicators: [
      { name: 'Water', value: 35, icon: 'water-icon.png' },
      { name: 'Energy', value: 74, icon: 'energy-icon.png' },
      { name: 'Food', value: 61, icon: 'food-icon.png' },
      { name: 'Ecosystem', value: 54, icon: 'ecosystem-icon.png' }
    ]
  },
];

export default data;
