// src/data.js
import storyImage1 from "../Assets/Images/story-image-1.png";
import storyImage2 from "../Assets/Images/story-image-2.png";
import storyImage3 from "../Assets/Images/story-image-3.png";
import partner1 from "../Assets/Images/partner-1.png";
import partner2 from "../Assets/Images/partner-2.png";
import partner3 from "../Assets/Images/partner-3.png";
import userImg from "../Assets/Images/user-img.png";
const storiesData = [
    {
        id: 1,
        title: "Sama Al Sarhan Pilot: WEFE Nexus approach",
        date: "03 JUNE 2024",
        profilePic: userImg,
        profileName: "Ahmad Jaber",
        image: storyImage1,
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. ",
        thumbnails: [storyImage1,storyImage2, storyImage1,storyImage2,storyImage3,storyImage3,storyImage1,storyImage2, storyImage1,storyImage2,storyImage3,storyImage3],
        storyInfo: {
            'Sector': "Water management and access",
            'Implementing Leader (Lead Organisation)': "INWRDAM",
            'Donor / Funding Agency' : "AFD, Embassy of Netherland",
            'Starting Date': "03 JANUARY 2024",
            'Duration': "6 months",
            'Organisation': "INWRDAM",
            'Organisation Type': "Inter-Governmental Organizations (IGOs)",
            'Sustainability Status of Story': "Ongoing Projects",
        },
        storyBackground: "Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Integer quis lorem malesuada, scelerisque velit et, vulputate neque. Sed vel sagittis est, non egestas velit. Nam sollicitudin volutpat nibh, a egestas arcu viverra non. Sed tristique, velit sed venenatis finibus, arcu purus aliquet turpis, et venenatis orci felis sit amet lacus. Fusce eu justo id erat vehicula pharetra. Nunc at diam vel felis varius fermentum. Etiam finibus dolor a ultricies pretium. Mauris aliquet metus ac ex aliquet, non fermentum eros facilisis. Suspendisse potenti. Duis eget arcu ac sapien facilisis pellentesque. Morbi sodales metus at urna vulputate, quis dictum ligula lobortis. Pellentesque vel enim vitae ligula vestibulum volutpat. Mauris fringilla felis ac ante dictum, a bibendum ligula venenatis.\n" +
            "Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Integer quis lorem malesuada, scelerisque velit et, vulputate neque. Sed vel sagittis est, non egestas velit. Nam sollicitudin volutpat nibh, a egestas arcu viverra non. Sed tristique, velit sed venenatis finibus, arcu purus aliquet turpis, et venenatis orci felis sit amet lacus. Fusce eu justo id erat vehicula pharetra. Nunc at diam vel felis varius fermentum. Etiam finibus dolor a ultricies pretium. ",
        storyDescription: "Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Integer quis lorem malesuada, scelerisque velit et, vulputate neque. Sed vel sagittis est, non egestas velit. Nam sollicitudin volutpat nibh, a egestas arcu viverra non. Sed tristique, velit sed venenatis finibus, arcu purus aliquet turpis, et venenatis orci felis sit amet lacus. Fusce eu justo id erat vehicula pharetra. Nunc at diam vel felis varius fermentum. Etiam finibus dolor a ultricies pretium. Mauris aliquet metus ac ex aliquet, non fermentum eros facilisis. Suspendisse potenti. Duis eget arcu ac sapien facilisis pellentesque. Morbi sodales metus at urna vulputate, quis dictum ligula lobortis. Pellentesque vel enim vitae ligula vestibulum volutpat. Mauris fringilla felis ac ante dictum, a bibendum ligula venenatis. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Integer quis lorem malesuada, scelerisque velit et, vulputate neque. Sed vel sagittis est, non egestas velit. Nam sollicitudin volutpat nibh, a egestas arcu viverra non. Sed tristique, velit sed venenatis finibus, arcu purus aliquet turpis, et venenatis orci felis sit amet lacus. Fusce eu justo id erat vehicula pharetra. Nunc at diam vel felis varius fermentum. Etiam finibus dolor a ultricies pretium. Mauris aliquet metus ac ex aliquet, non fermentum eros facilisis. Suspendisse potenti. Duis eget arcu ac sapien facilisis pellentesque. Morbi sodales metus at urna vulputate, quis dictum ligula lobortis. Pellentesque vel enim vitae ligula vestibulum volutpat. Mauris fringilla felis ac ante dictum, a bibendum ligula venenatis.\n" +
            "\n" +
            "Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Integer quis lorem malesuada, scelerisque velit et, vulputate neque. Sed vel sagittis est, non egestas velit. Nam sollicitudin volutpat nibh, a egestas arcu viverra non. Sed tristique, velit sed venenatis finibus, arcu purus aliquet turpis, et venenatis orci felis sit amet lacus. Fusce eu justo id erat vehicula pharetra. Nunc at diam vel felis varius fermentum. Etiam finibus dolor a ultricies pretium. Mauris aliquet metus ac ex aliquet, non fermentum eros facilisis. Suspendisse potenti. Duis eget arcu ac sapien facilisis pellentesque. Morbi sodales metus at urna vulputate, quis dictum ligula lobortis. Pellentesque vel enim vitae ligula vestibulum volutpat. Mauris fringilla felis ac ante dictum, a bibendum ligula venenatis. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Integer quis lorem malesuada, scelerisque velit et, vulputate neque. Sed vel sagittis est, non egestas velit. Nam sollicitudin volutpat nibh, a egestas arcu viverra non. Sed tristique, velit sed venenatis finibus, arcu purus aliquet turpis, et venenatis orci felis sit amet lacus. Fusce eu justo id erat vehicula pharetra. Nunc at diam vel felis varius fermentum. Etiam finibus dolor a ultricies pretium. Mauris aliquet metus ac ex aliquet, non fermentum eros facilisis. Suspendisse potenti. Duis eget arcu ac sapien facilisis pellentesque. Morbi sodales metus at urna vulputate, quis dictum ligula lobortis. Pellentesque vel enim vitae ligula vestibulum volutpat. Mauris fringilla felis ac ante dictum, a bibendum ligula venenatis.",
        authorProfileLink: "#",
        storyLink: "#",
        pdfLink: "path/to/pdf1.pdf",
        country: {
            name: "Jordan",
            location:"Mafraq Governorate",
            mapLink: "<iframe src=\"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3492525.674884207!2d34.4812182676548!3d31.25756843235755!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15006f476664de99%3A0x8d285b0751264e99!2sJordan!5e0!3m2!1sen!2sjo!4v1720526864782!5m2!1sen!2sjo\" width=\"600\" height=\"450\" style=\"border:0;\" allowfullscreen=\"\" loading=\"lazy\" referrerpolicy=\"no-referrer-when-downgrade\"></iframe>",
        },
        partners: [
            {
                image: partner1,
                name: "Organisation name",
                sector: "Partner 1 description",
            },
            {
                image: partner2,
                name: "Organisation name",
                sector: "Partner 2 description",
            },
            {
                image: partner3,
                name: "Organisation name",
                sector: "Partner 2 description",
            },
        ],
    },
    {
        id: 2,
        title: "WEFE HELD A TRAINING WORKSHOP ON CATCHMENT PLANNING FOR WATER HARVESTING AND SOIL CONSERVATION",
        date: "03 JUNE 2024",
        profilePic: userImg,
        profileName: "Ahmad Jaber",
        image: storyImage2,
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. ",

    },
    {
        id: 3,
        title: "WEFE HELD A TRAINING WORKSHOP ON CATCHMENT PLANNING FOR WATER HARVESTING AND SOIL CONSERVATION",
        date: "03 JUNE 2024",
        profilePic: userImg,
        profileName: "Ahmad Jaber",
        image:storyImage3,
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. ",

    },
    {
        id: 4,
        title: "Section Title 4",
        date: "2024-07-09",
        profilePic: userImg,
        profileName: "Ahmad Jaber",
        image: storyImage1,
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. ",

    },
    {
        id: 5,
        title: "Section Title 5",
        date: "2024-07-09",
        profilePic: userImg,
        profileName: "Ahmad Jaber",
        image: storyImage2,
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. ",

    },
    {
        id: 6,
        title: "Section Title 6",
        date: "2024-07-09",
        profilePic:userImg,
        profileName: "Ahmad Jaber",
        image:storyImage3,
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. ",

    },
    {
        id: 7,
        title: "Section Title 6",
        date: "2024-07-09",
        profilePic: userImg,
        profileName: "Profile Name 6",
        image:storyImage3,
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. ",

    },
    {
        id: 8,
        title: "Section Title 6",
        date: "2024-07-09",
        profilePic:userImg,
        profileName: "Profile Name 6",
        image:storyImage3,
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. ",

    },
    {
        id:9,
        title: "Section Title 6",
        date: "2024-07-09",
        profilePic: userImg,
        profileName: "Profile Name 6",
        image:storyImage3,
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. ",

    },
];

export default storiesData;
