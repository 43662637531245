import React from 'react';
import './Login.css';
import { Link } from 'react-router-dom';
export default function Login() {
    return (
        <div className="login-container">
            <div className="login-box">
                <h1>Welcome To INWRDAM</h1>
                <h2>It’s great to have you again!</h2>
                <form>
                    <div className="form-group">
                        <label htmlFor="email">Email Address</label>
                        <input placeholder='Email Address' type="email" id="email" className="form-control" required />
                    </div>
                    <div className="form-group">
                        <label  htmlFor="password">Password</label>
                        <input placeholder='Password' type="password" id="password" className="form-control" required />
                    </div>
                    <button type="submit" className="btn btn-primary">Login</button>
                </form>
              <span>  Forgot your password? <Link to="/forgot-password" className="forgot-password-link"> Reset password </Link></span>
            </div>
        </div>
    );
}
