import React, { useState, useEffect } from 'react';
import { sliders as sliderData } from "../../sliderData";
import './SliderSection.css';

const SliderSection = () => {
    const [slides, setSlides] = useState([]);
    useEffect(() => {
        const fetchSlides = async () => {
            setSlides(sliderData);
        };
        fetchSlides();
    }, []);
    const [currentSlide, setCurrentSlide] = useState(0);
    return (
        <div className="slider-container">
            {slides.length > 0 && (
                <>
                    <div
                        className="slide"
                        style={{
                            backgroundImage: slides[currentSlide].type === 'image' ? `url(${slides[currentSlide].content.url})` : 'none'
                        }}
                    >
                        {slides[currentSlide].type === 'video' ? (
                            <video autoPlay muted loop>
                                <source src={slides[currentSlide].content.url} type="video/mp4" />
                            </video>
                        ) : null}
                        <div className="content">
                            <h1>{slides[currentSlide].content.title}</h1>
                            <p>{slides[currentSlide].content.subtitle}</p>
                        </div>
                    </div>
                    <div className="dots">
                        {slides.map((slide, index) => (
                            <span
                                key={index}
                                className={index === currentSlide ? 'dot active' : 'dot'}
                                onClick={() => setCurrentSlide(index)}
                            ></span>
                        ))}
                    </div>
                </>
            )}
            <div className="wave"></div>
        </div>

    );
};
export default SliderSection;