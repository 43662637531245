import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import LoginMenu from './LoginMenu';
import searchIcon from '../../Assets/Images/search-icon.svg';
import logoIcon from '../../Assets/Images/login-icon.svg';
import './Header.css';

export default function Header({ siteInfo }) {
    const [menuVisible, setMenuVisible] = useState(false);
    const [searchVisible, setSearchVisible] = useState(false);
    const [mobileNavVisible, setMobileNavVisible] = useState(false);
    const menuRef = useRef();
    const searchRef = useRef();
    const mobileNavRef = useRef();

    const toggleMenu = () => {
        setMenuVisible(!menuVisible);
    };

    const toggleSearch = () => {
        setSearchVisible(!searchVisible);
    };

    const toggleMobileNav = () => {
        setMobileNavVisible(!mobileNavVisible);
    };

    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setMenuVisible(false);
        }
        if (searchRef.current && !searchRef.current.contains(event.target)) {
            setSearchVisible(false);
        }
        if (mobileNavRef.current && !mobileNavRef.current.contains(event.target)) {
            setMobileNavVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    if (!siteInfo) {
        return <div>Loading...</div>;
    }

    return (
        <header id="header" className="header d-flex align-items-center fixed-top">
            <title>{siteInfo ? siteInfo.site_name : 'Loading...'}</title>
            <link rel="icon" href={siteInfo.logo} />

            <div className="container-fluid container-xl d-flex align-items-center justify-content-between">
                <Link to="/">
                    <div className="logo d-flex align-items-center">
                        <img src={siteInfo.logo} alt="Logo" />
                        {/* You can render other parts of siteInfo here */}
                    </div>
                </Link>
                <nav id="navbar" className={`navbar ms-auto ${mobileNavVisible ? 'navbar-mobile' : ''}`} ref={mobileNavRef}>
                    <ul>
                        <li><Link to="/">About WEFE</Link></li>
                        <li><Link to="/wefe-index">WEFE Index</Link></li>
                        <li><Link to="/news-and-events">Interactive Maps</Link></li>
                        <li><Link to="/wefe-stories">WEFE Stories</Link></li>
                        <li><Link to="/news-and-events">News & Events</Link></li>
                        <li><Link to="/publications">Publications</Link></li>
                        <li><Link to="/arabic">العربية</Link></li>
                    </ul>
                    <i className="bi bi-list mobile-nav-toggle" onClick={toggleMobileNav}></i>
                </nav>
                <div className="position-relative right-menu" ref={menuRef}>
                    <a className="mx-2 js-search-open" onClick={toggleSearch}><img className='search-icon' src={searchIcon} alt="Search" /></a>
                    <a className="mx-2" onClick={toggleMenu}><img className='login-icon' src={logoIcon} alt="Login" /></a>
                    <LoginMenu isVisible={menuVisible} />
                    {searchVisible && (
                        <div className="search-form-wrap js-search-form-wrap" ref={searchRef}>
                            <form action="search-result.html" className="search-form">
                                <span className="icon bi-search"></span>
                                <input type="text" placeholder="Search" className="form-control" />
                                <button className="btn js-search-close" onClick={toggleSearch}><span className="bi-x"></span></button>
                            </form>
                        </div>
                    )}
                </div>
            </div>
        </header>
    );
}
