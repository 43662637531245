// src/pages/EventDetails.js

import React from 'react';
import Banner from '../Components/EventDetailsBanner';
import SliderWithThumbnails from '../Components/EventDetailsSlider';
import './EventDetails.css';


const EventDetails = ({ event }) => {
    // Provide a default empty array if event.images is undefined
    const images = event.images || [];

    return (
        <div className="event-details">
            <Banner title="Event Details" />
            <div className="slider">
                <SliderWithThumbnails images={images} />
            </div>
            <div className="event-info">
                <div className="left-part">
                    <div className="text">
                        <h2>{event.title}</h2>
                        <p>{event.subtitle}</p>
                        <hr />
                        <h3>General Information</h3>
                        <div className="info">
                            <div>
                                <strong>Type:</strong> {event.type}
                            </div>
                            <div>
                                <strong>Time and Date:</strong> {event.date}
                            </div>
                            <div>
                                <strong>Target Audience:</strong> {event.targetAudience}
                            </div>
                            <div>
                                <strong>Implementing Leader:</strong> {event.leader}
                            </div>
                            <div>
                                <strong>Duration:</strong> {event.duration}
                            </div>
                            <div>
                                <strong>Organisation:</strong> {event.organization}
                            </div>
                            <div>
                                <strong>Organisation Type:</strong> {event.orgType}
                            </div>
                            <div>
                                <strong>Sustainability Status of Story:</strong> {event.sustainability}
                            </div>
                        </div>
                    </div>
                    <div className="agenda">
                        <h3>Agenda Outline</h3>
                        <ul>
                            {event.agenda && event.agenda.map((point, index) => (
                                <li key={index}>{point}</li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div className="right-part">
                    <div className="event-description">
                        <h3>Event Description</h3>
                        <p>{event.description}</p>
                    </div>
                    <div className="event-impact">
                        <h3>Event Impact</h3>
                        <p>{event.impact}</p>
                    </div>
                    <div className="key-learnings">
                        <h3>Key Learnings</h3>
                        <p>{event.learnings}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EventDetails;