import React from 'react';
import { Form, Nav } from 'react-bootstrap';
import { FaGlobe, FaCalendarAlt } from 'react-icons/fa';
import countriesIcon from '../../Assets/Images/countries-icon.svg'
import yearIcon from '../../Assets/Images/year-icon.svg'
const WefeIndexFilters = ({ handleFilterChange, activeTab, setActiveTab }) => {
        const handleTabClick = (tab) => {
                setActiveTab(tab);
                handleFilterChange('category', tab);
        };

        return (
            <div className="filters my-4 text-center">
                    <Form inline className="d-flex justify-content-center align-items-center">
                            <Form.Group className="mx-2">
                                    <div className="input-group">
                                            <Form.Control
                                                as="select"
                                                className="filter-select"
                                                onChange={(e) => handleFilterChange('country', e.target.value)}
                                            >
                                                    <option value="">Countries on</option>
                                                    <option value="Jordan">Jordan</option>
                                                    <option value="Türkiye">Türkiye</option>
                                                    <option value="Canada">Canada</option>
                                                    <option value="Iraq">Iraq</option>
                                                    <option value="Korea">Korea</option>
                                                    <option value="Saudi Arabia">Saudi Arabia</option>
                                                    <option value="Algeria">Algeria</option>
                                                    <option value="Syria">Syria</option>
                                                    <option value="USA">USA</option>
                                                    <option value="Japan">Japan</option>
                                                    <option value="India">India</option>
                                            </Form.Control>
                                            <div className="input-group-append">
                        <span className="input-group-text sss">
                            <img src={countriesIcon}/>
                        </span>
                                            </div>
                                    </div>
                            </Form.Group>

                            {/* Year Input */}
                            <Form.Group className="mx-2">
                                    <div className="input-group">
                                            <Form.Control
                                                type="number"
                                                placeholder="Year"
                                                min="1900"
                                                max="2100"
                                                onChange={(e) => handleFilterChange('year', e.target.value)}
                                            />
                                            <div className="input-group-append">
                        <span className="input-group-text">
                           <img src={yearIcon}/>
                        </span>
                                            </div>
                                    </div>
                            </Form.Group>
                    </Form>
                    <Nav variant="tabs" defaultActiveKey="all" className="justify-content-center mt-3">
                            <Nav.Item>
                                    <Nav.Link eventKey="all" active={activeTab === 'all'} onClick={() => handleTabClick('all')}>All</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                    <Nav.Link eventKey="water" active={activeTab === 'water'} onClick={() => handleTabClick('water')}>Water</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                    <Nav.Link eventKey="energy" active={activeTab === 'energy'} onClick={() => handleTabClick('energy')}>Energy</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                    <Nav.Link eventKey="food" active={activeTab === 'food'} onClick={() => handleTabClick('food')}>Food</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                    <Nav.Link eventKey="ecosystem" active={activeTab === 'ecosystem'} onClick={() => handleTabClick('ecosystem')}>Ecosystem</Nav.Link>
                            </Nav.Item>
                    </Nav>
            </div>

        );
};

export default WefeIndexFilters;
