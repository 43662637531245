import React, { useState, useEffect } from 'react';
import './AboutSection.css';
const AboutSection = () => {
    const [aboutContent, setAboutContent] = useState(null);
    useEffect(() => {
        const fetchAboutData = async () => {
            try {
                const response = await fetch('https://apis-wefe.haka-jo.com/website/info');
                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }
                const data = await response.json();
                if (data.error_flag === 0) {
                    const aboutData = {
                        title: data.result.banner_title,
                        subtitle: data.result.banner_subtitle,
                        body: data.result.welcome_content,
                        image: data.result.welcome_image,
                        statistics: [
                            {
                                value: data.result.statistic_1_value,
                                label: data.result.statistic_1_label,
                            },
                            {
                                value: data.result.statistic_2_value,
                                label: data.result.statistic_2_label,
                            },
                            {
                                value: data.result.statistic_3_value,
                                label: data.result.statistic_3_label,
                            },
                            {
                                value: data.result.statistic_4_value,
                                label: data.result.statistic_4_label,
                            }
                        ]
                    };
                    setAboutContent(aboutData);
                } else {
                    throw new Error(data.message);
                }
            } catch (error) {
                console.error('Error fetching about data:', error);
            }
        };
        fetchAboutData();
    }, []);

    if (!aboutContent) {
        return <div>Loading...</div>;
    }
    return (
        <section className="about">
            <div className="about-first-part container">
                <div className="about-title">
                    <h2>{aboutContent.title}</h2>
                    <p>{aboutContent.subtitle}</p>
                </div>
                <div className="about-body">
                    <div className="row">
                        <div className="col-md-6">
                            <p dangerouslySetInnerHTML={{ __html: aboutContent.body }}></p>
                        </div>
                        <div className="col-md-6">
                            {aboutContent.image && <img src={aboutContent.image} alt="Placeholder" />}
                        </div>
                    </div>
                </div>
            </div>
            <div className="about-number-info container-fluid">
                <div className="row">
                    <div className="about-number-info-title">
                        <h2>Our Impact</h2>
                        <p>Achievements throughout the years</p>
                    </div>
                    {aboutContent.statistics.map((statistic, index) => (
                        <div className="col-md-3 info-container" key={index}>
                            <p className="info-number">{statistic.value}</p>
                            <p className="info-title">{statistic.label}</p>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};
export default AboutSection;
