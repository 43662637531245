// src/pages/Home.js
import React from 'react';
import PartnerSection from '../Components/PartnerSection/PartnerSection';
import SliderSection from "../Components/SliderSection/SliderSection";
import AboutSection from "../Components/AboutSection/AboutSection";
import ContactUsSection from "../Components/ContactUsSection/ContactUsSection";
const Home = () => {
    return (
        <main id="main">
            {/* Hero Slider Section */}
            <section id="hero-slider" className="hero-slider">
                <SliderSection />
                {/*</div>*/}
            </section>
            {/* End Hero Slider Section */}
                {/* About Section */}
                <AboutSection />
                {/* End About Section */}
                {/* Partners Section */}
                <PartnerSection />
                {/* End Partners Section */}
                {/* Contact Section */}
            <ContactUsSection />
                {/* End Contact Section */}
        </main>
    );
};
export default Home;
