// src/Components/SliderWithThumbnails.js
import React, { useState } from 'react';
import Slider from 'react-slick';
import './SliderWithThumbnails.css'; // Import custom styles

const SliderWithThumbnails = ({ images }) => {
    const [nav1, setNav1] = useState(null);
    const [nav2, setNav2] = useState(null);

    const settingsMain = {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false, // Remove arrows for main slider
        infinite: true,
        useCSS: true,
        adaptiveHeight: true,
    };

    const settingsThumbs = {
        slidesToShow: 5,
        slidesToScroll: 1,
        arrows: true, // Enable arrows for thumbnail slider
        infinite: true,
        useCSS: true,
        adaptiveHeight: true,
        focusOnSelect: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                },
            },
        ],
    };

    return (
        <div className="slider-container">
            <Slider {...settingsMain} asNavFor={nav2} ref={slider => setNav1(slider)}>
                {images.map((img, index) => (
                    <div key={index}>
                        <img src={img} alt={`Slide ${index}`} />
                    </div>
                ))}
            </Slider>
            <Slider {...settingsThumbs} asNavFor={nav1} ref={slider => setNav2(slider)} className="thumbnail-slider">
                {images.map((img, index) => (
                    <div key={index}>
                        <img src={img} alt={`Thumbnail ${index}`} />
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default SliderWithThumbnails;
