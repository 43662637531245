// src/components/Slider.js

import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// import './Slider.css';

const SliderWithThumbnails = ({ images }) => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: false,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    dots: false,
                }
            }
        ]
    };

    return (
        <div className="slider-container">
            <Slider {...settings}>
                {images.map((image, index) => (
                    <div key={index} className="slide">
                        <img src={image} alt={`Slide ${index}`} />
                    </div>
                ))}
            </Slider>
            <div className="thumbnails">
                {images.map((image, index) => (
                    <div key={index} className="thumbnail">
                        <img src={image} alt={`Thumbnail ${index}`} />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default SliderWithThumbnails;
