import { ReactSVG } from 'react-svg';
import logoUfm from '../../Assets/Images/logo-ufm.svg';
import LogoSida from '../../Assets/Images/Logo_Sida.svg';
import logo from '../../Assets/Images/logo.svg';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './Footer.css'
export default function Footer() {
    const currentYear = new Date().getFullYear();
    return (
        <footer id="footer" className="footer">
            <div className="footer-content">
                <div className="container">
                    <div className="row g-5">
                        <div className="col-lg-4 first-part">
                            <img src={logoUfm} alt="Logo" />
                            <img src={logo} alt="Logo" />
                            <img src={LogoSida} alt="Logo" />
                            {/*<h3 className="footer-heading">About ZenBlog</h3>*/}
                            <p>
                                Water scarcity fuels global tensions.
                                Prioritizing cooperation and effective management can address this.
                                Our research and advocacy bridge interests for mutual benefit.
                            </p>
                        </div>
                        <div className="col-6 col-lg-2">
                            <h3 className="footer-heading">About</h3>
                            <ul className="footer-links list-unstyled">
                                <li><a href="src/Components#"> Wefe Hub</a></li>
                                <li><a href="src/Components#"> Our Partners</a></li>
                                <li><a href="src/Components#"> Contact Us</a></li>
                            </ul>
                        </div>
                        <div className="col-6 col-lg-2">
                            <h3 className="footer-heading">WEFE Index</h3>
                            <h3 className="footer-heading">Interactive Maps</h3>
                            <ul className="footer-links list-unstyled">
                                <li><a href="src/Components#"> Global Map Level</a></li>
                                <li><a href="src/Components#"> Country Map level</a></li>
                            </ul>
                        </div>
                        <div className="col-6 col-lg-2">
                            <h3 className="footer-heading">WEFE Stories</h3>
                            <h3 className="footer-heading">News & Events</h3>
                            <ul className="footer-links list-unstyled">
                                <li><a href="src/Components#">News</a></li>
                                <li><a href="src/Components#"> Events</a></li>
                            </ul>
                        </div>
                        <div className="col-6 col-lg-2">
                            <h3 className="footer-heading">Publications</h3>
                            <h3 className="footer-heading">Sign In</h3>
                            <ul className="footer-links list-unstyled">
                                <li><a href="src/Components#">Terms & Conditions</a></li>
                                <li><a href="src/Components#">Privacy Policy</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-legal">
                <div className="container">
                    <div className="row justify-content-between">
                        <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                            <div className="copyright">
                                 Copyright <strong><span>{currentYear}</span> © </strong>. All Rights Reserved
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="social-links mb-3 mb-lg-0 text-center text-md-end">
                                <a href="src/Components#" className="facebook"><i className="bi bi-facebook"></i></a>
                                <a href="src/Components#" className="twitter"><i className="bi bi-twitter-x"></i></a>
                                <a href="src/Components#" className="linkedin"><i className="bi bi-linkedin"></i></a>
                                <a href="src/Components#" className="google-plus"><i className="bi bi-youtube"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}
