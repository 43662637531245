// src/newsEventsData.js
import EventImage from "../Assets/Images/card-events-1.png";
const newsEventsData = [
    {
        image: EventImage,
        date: "03 JUNE 2024",
        text: "Workshop for Training Needs Assessment for Ministries and Sectors Concerned with WEFE in Jordan, and Identifying L…",
        region: "Region 1",
        year: "2024",
        keywords: ["news", "event"],
        sector: "Sector 1"
    },
    {
        image: EventImage,
        date: "03 JUNE 2024",
        text: "Workshop for Training Needs Assessment for Ministries and Sectors Concerned with WEFE in Jordan, and Identifying L…",
        region: "Region 2",
        year: "2023",
        keywords: ["update", "event"],
        sector: "Sector 2"
    },
    {
        image: EventImage,
        date: "03 JUNE 2024",
        text: "Workshop for Training Needs Assessment for Ministries and Sectors Concerned with WEFE in Jordan, and Identifying L…",
        region: "Region 2",
        year: "2023",
        keywords: ["update", "event"],
        sector: "Sector 2"
    },
    {
        image:EventImage,
        date: "2024-07-02",
        text: "Workshop for Training Needs Assessment for Ministries and Sectors Concerned with WEFE in Jordan, and Identifying L…",
        region: "Region 2",
        year: "2023",
        keywords: ["update", "event"],
        sector: "Sector 2"
    },
    {
        image: EventImage,
        date: "2024-07-02",
        text: "Workshop for Training Needs Assessment for Ministries and Sectors Concerned with WEFE in Jordan, and Identifying L…",
        region: "Region 2",
        year: "2023",
        keywords: ["update", "event"],
        sector: "Sector 2"
    },
    {
        image: EventImage,
        date: "2024-07-02",
        text: "Workshop for Training Needs Assessment for Ministries and Sectors Concerned with WEFE in Jordan, and Identifying L…",
        region: "Region 2",
        year: "2023",
        keywords: ["update", "event"],
        sector: "Sector 2"
    },
    {
        image: EventImage,
        date: "2024-07-02",
        text: "Workshop for Training Needs Assessment for Ministries and Sectors Concerned with WEFE in Jordan, and Identifying L…",
        region: "Region 2",
        year: "2023",
        keywords: ["update", "event"],
        sector: "Sector 2"
    },
    {
        image: EventImage,
        date: "2024-07-02",
        text: "Workshop for Training Needs Assessment for Ministries and Sectors Concerned with WEFE in Jordan, and Identifying L…",
        region: "Region 2",
        year: "2023",
        keywords: ["update", "event"],
        sector: "Sector 2"
    },
    {
        image:EventImage,
        date: "2024-07-02",
        text: "Workshop for Training Needs Assessment for Ministries and Sectors Concerned with WEFE in Jordan, and Identifying L…",
        region: "Region 2",
        year: "2023",
        keywords: ["update", "event"],
        sector: "Sector 2"
    },
    {
        image: EventImage,
        date: "2024-07-02",
        text: "Workshop for Training Needs Assessment for Ministries and Sectors Concerned with WEFE in Jordan, and Identifying L…",
        region: "Region 2",
        year: "2023",
        keywords: ["update", "event"],
        sector: "Sector 2"
    },
    {
        image: "image2.jpg",
        date: "2024-07-02",
        text: "Workshop for Training Needs Assessment for Ministries and Sectors Concerned with WEFE in Jordan, and Identifying L…",
        region: "Region 2",
        year: "2023",
        keywords: ["update", "event"],
        sector: "Sector 2"
    },
    {
        image: "image2.jpg",
        date: "2024-07-02",
        text: "Sample news item 2",
        region: "Region 2",
        year: "2023",
        keywords: ["update", "event"],
        sector: "Sector 2"
    },
    {
        image: "image2.jpg",
        date: "2024-07-02",
        text: "Sample news item 2",
        region: "Region 2",
        year: "2023",
        keywords: ["update", "event"],
        sector: "Sector 2"
    },
    {
        image: "image2.jpg",
        date: "2024-07-02",
        text: "Sample news item 2",
        region: "Region 2",
        year: "2023",
        keywords: ["update", "event"],
        sector: "Sector 2"
    },
    {
        image: "image2.jpg",
        date: "2024-07-02",
        text: "Sample news item 2",
        region: "Region 2",
        year: "2023",
        keywords: ["update", "event"],
        sector: "Sector 2"
    },
    {
        image: "image2.jpg",
        date: "2024-07-02",
        text: "Sample news item 2",
        region: "Region 2",
        year: "2023",
        keywords: ["update", "event"],
        sector: "Sector 2"
    },
    {
        image: "image2.jpg",
        date: "2024-07-02",
        text: "Sample news item 2",
        region: "Region 2",
        year: "2023",
        keywords: ["update", "event"],
        sector: "Sector 2"
    },
    {
        image: "image2.jpg",
        date: "2024-07-02",
        text: "Sample news item 2",
        region: "Region 2",
        year: "2023",
        keywords: ["update", "event"],
        sector: "Sector 2"
    },
    {
        image: "image2.jpg",
        date: "2024-07-02",
        text: "Sample news item 2",
        region: "Region 2",
        year: "2023",
        keywords: ["update", "event"],
        sector: "Sector 2"
    },
    {
        image: "image2.jpg",
        date: "2024-07-02",
        text: "Sample news item 2",
        region: "Region 2",
        year: "2023",
        keywords: ["update", "event"],
        sector: "Sector 2"
    },
    {
        image: "image2.jpg",
        date: "2024-07-02",
        text: "Sample news item 2",
        region: "Region 2",
        year: "2023",
        keywords: ["update", "event"],
        sector: "Sector 2"
    },
    {
        image: "image2.jpg",
        date: "2024-07-02",
        text: "Sample news item 2",
        region: "Region 2",
        year: "2023",
        keywords: ["update", "event"],
        sector: "Sector 2"
    },

];

export default newsEventsData;
