import React, { useState, useEffect } from 'react';
import Header from './Components/Header/Header';
import Footer from './Components/Footer/Footer';
import Home from './Pages/Home';
import NewsAndEvents from './Pages/NewsAndEvents';
import WefeIndex from './Pages/WefeIndex/WefeIndex';
import Stories from './Pages/Stories';
import StoryDetailedView from './Pages/StoryDetailedView';
import Login from './Pages/Login/Login';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

function App() {
    const [siteInfo, setSiteInfo] = useState(null);

    useEffect(() => {
        fetch('https://apis-wefe.haka-jo.com/website/info')
            .then(response => response.json())
            .then(data => {
                if (data.error_flag === 0) {
                    setSiteInfo(data.result);
                } else {
                    console.error('Failed to fetch site info:', data.message);
                }
            })
            .catch(error => {
                console.error('Error fetching site info:', error);
            });
    }, []);

    return (
        <Router>
            <div>
                <Header siteInfo={siteInfo} />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/news-and-events" element={<NewsAndEvents />} />
                    <Route path="/wefe-stories" element={<Stories />} />
                    <Route path="/wefe-index" element={<WefeIndex />} />
                    <Route path="/details/:id" element={<StoryDetailedView />} />
                    <Route path="/login" element={<Login />} />
                </Routes>
                <Footer />
            </div>
        </Router>
    );
}

export default App;
