// src/pages/NewsAndEvents.js
import React, { useState, useEffect } from 'react';
import Banner from '../Components/Banner';
import Filters from '../Components/Filters';
import Card from '../Components/Card';
import PaginationDots from '../Components/PaginationDots';
import './NewsAndEvents.css';
import EventDetails from './EventDetails'; // Import EventDetails component

import newsEventsData from '../data/newsEventsData'; // Import the data

const NewsAndEvents = () => {
    const [filters, setFilters] = useState({ region: '', year: '', keywords: '', sector: '' });
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const cardsPerPage = 6;
    const [regions, setRegions] = useState([]);
    const [sectors, setSectors] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState(null); // State to track selected event


    useEffect(() => {
        // Set data from newsEventsData
        setData(newsEventsData);

        // Extract unique regions and sectors for filter options
        const uniqueRegions = [...new Set(newsEventsData.map(item => item.region))];
        const uniqueSectors = [...new Set(newsEventsData.map(item => item.sector))];
        setRegions(uniqueRegions);
        setSectors(uniqueSectors);
    }, []);

    const handleFilterChange = (filterType, value) => {
        setFilters({ ...filters, [filterType]: value });
        setCurrentPage(1); // Reset to first page when filters change
    };

    // Filter data based on current filters
    const filteredData = data.filter(item => {
        return (
            (filters.region ? item.region === filters.region : true) &&
            (filters.year ? new Date(item.date).getFullYear() === new Date(filters.year).getFullYear() : true) &&
            (filters.keywords ? item.keywords.includes(filters.keywords) : true) &&
            (filters.sector ? item.sector === filters.sector : true)

        );
    });

    // Pagination logic
    const indexOfLastCard = currentPage * cardsPerPage;
    const indexOfFirstCard = indexOfLastCard - cardsPerPage;
    const currentCards = filteredData.slice(indexOfFirstCard, indexOfLastCard);
    const totalPages = Math.ceil(filteredData.length / cardsPerPage);

    const paginate = pageNumber => setCurrentPage(pageNumber);
    // Handle click on a card to show details
    const handleClickCard = (event) => {
        setSelectedEvent(event);
    };
    // Render EventDetails if event is selected
    if (selectedEvent) {
        return <EventDetails event={selectedEvent} />;
    }
    return (
        <div className="news-and-events">
            <Banner title="News and Events" />
            <div className="news-and-events-page-content">
            <Filters filters={filters} onFilterChange={handleFilterChange} regions={regions} sectors={sectors} />
            <div className="cards container">
                {currentCards.map((item, index) => (
                    <div key={index} className="card"   onClick={() => handleClickCard(item)}>
                        <img src={item.image} alt="Card" />
                        <div className="card-footer">

                            <div className="footer-content">
                                <span>{item.date}</span>
                                <p>{item.text}</p>
                            </div>
                            <div className='footer-button'>
                            <button className="read-more">Read More</button>
                            <span className="arrow-icon">→</span>
                            </div>
                        </div>

                    </div>
                ))}
            </div>
            <PaginationDots total={totalPages} current={currentPage} paginate={paginate} />
            </div>
        </div>
    );
};

export default NewsAndEvents;