// src/Sections.js
import React, { useState } from 'react';
import Banner from "../Components/Banner";
import storiesData from '../data/storiesData'; // Import the data
import './Stories.css';
import { Link } from 'react-router-dom';

const Section = ({ id,title, date, profilePic, profileName, image, reverse ,description}) => (
    <div style={{ display: 'flex', flexDirection: reverse ? 'row-reverse' : 'row' }}>
        <div style={{ flex: 1 }}>
            <img src={image} alt={title} style={{ width: '100%', height: 'auto' }} />
        </div>
        <div className='story-section' style={{ flex: 1 }}>
            <h2>{title}</h2>
            <p className="date">{date}</p>
            <div className="profile" style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                <img src={profilePic} alt={profileName} style={{ width: '30px', height: '30px', borderRadius: '50%', marginRight: '5px' }} />
                <span>{profileName}</span>
            </div>
            <p className="description">{description}</p>
            <Link to={`/details/${id}`}> <button className="btn btn-primary view-btn">View Details</button></Link>
        </div>
    </div>
);

const Sections = () => {
    const [sections, setSections] = useState(storiesData.slice(0, 3));
    const [visibleSections, setVisibleSections] = useState(3);

    const loadMore = () => {
        const nextSections = storiesData.slice(visibleSections, visibleSections + 3);
        setSections([...sections, ...nextSections]);
        setVisibleSections(visibleSections + 3);
    };

    return (
        <div>
            <Banner title="WEFE Stories" description="Harmony in Action" />
            {sections.map((section, index) => (
                <Section key={section.id} {...section} reverse={index % 2 === 1} />
            ))}
            {visibleSections < storiesData.length && (
                <button className="btn btn-primary load-btn center" onClick={loadMore}>Load More</button>
            )}
        </div>
    );
};

export default Sections;
