import React from 'react';
import { Card as BootstrapCard } from 'react-bootstrap';

const WefeIndexCard = ({ country, rank, indicators, flag }) => (
    <BootstrapCard className="my-2 card wefe-index-card">
        <BootstrapCard.Body>
            <div className="card-header">
                <div className='country-info'>
                    <img src={flag} alt={`${country} flag`} />
                    <h5>{country}</h5>
                </div>
                <div>
                    <p>
                        <span className="rank-title">Rank</span><br />
                        <span className="rank-value">{rank}</span>
                    </p>
                </div>
            </div>
            {indicators.map((indicator, index) => (
                <div key={index} className="indicator">
                    <img src={indicator.icon} alt={indicator.name} />
                    <div className="indicator-info">
                        <div className="progress">
                            <div className={`progress-bar ${indicator.name.toLowerCase()}-progress1`} style={{ height: `${indicator.value}%` }}></div>
                            <div className={`progress-bar ${indicator.name.toLowerCase()}-progress2`} style={{ height: `${100 - indicator.value}%` }}></div>
                        </div>
                        <div className="indicator-name">
                            <span>{indicator.name}</span>
                            <span>{indicator.value}%</span>
                        </div>
                    </div>
                </div>
            ))}
        </BootstrapCard.Body>
    </BootstrapCard>
);

export default WefeIndexCard;
