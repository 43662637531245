// src/components/PaginationDots.js

import React from 'react';
import './PaginationDots.css';

const PaginationDots = ({ total, current, paginate }) => {
    const pageNumbers = [];

    for (let i = 1; i <= total; i++) {
        pageNumbers.push(i);
    }

    return (
        <div className="pagination-dots">
            {pageNumbers.map(number => (
                <span
                    key={number}
                    className={number === current ? 'active' : ''}
                    onClick={() => paginate(number)}
                ></span>
            ))}
        </div>
    );
};

export default PaginationDots;
