import React, { useState, useEffect } from 'react';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import './ContactUsSection.css';
import EmailIcon from "../../Assets/Images/email-icon.svg";
import PhoneIcon from "../../Assets/Images/phone-icon.svg";
import LocationIcon from "../../Assets/Images/location-icon.svg";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ContactUsSection = () => {
    const [name, setName] = useState('');
    const [mobile, setMobile] = useState('');
    const [message, setMessage] = useState('');
    const [contactContent, setContactUsContent] = useState(null);

    useEffect(() => {
        const fetchContactData = async () => {
            try {
                const response = await fetch('https://apis-wefe.haka-jo.com/website/info');
                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }
                const data = await response.json();
                if (data.error_flag === 0) {
                    const contactData = {
                        mobile: data.result.mobile,
                        email: data.result.email,
                        location: data.result.location,
                    };
                    setContactUsContent(contactData);
                } else {
                    throw new Error(data.message);
                }
            } catch (error) {
                console.error('Error fetching contact data:', error);
            }
        };
        fetchContactData();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('https://apis-wefe.haka-jo.com/website/contact_us', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    name,
                    mobile,
                    message,
                }),
            });
            const responseData = await response.json(); // Parse response body as JSON
            if (!response.ok) {
                throw new Error(responseData.message || 'Failed to submit form');
            }
            // Clear form fields after successful submission
            setName('');
            setMobile('');
            setMessage('');
            // Show success toast
            toast.success('Message sent successfully!');
        } catch (error) {
            console.error('Error submitting form:', error.message);
            toast.error(`Failed to send message: ${error.message}`);
        }
    };


    if (!contactContent) {
        return <div>Loading...</div>;
    }

    return (
        <section className="contact-us container">
            <div className="contact-us-title">
                <h2>Contact Us</h2>
                <p>We’re here to answer your questions</p>
            </div>
            <div className="row">
                {/* Left Part - Form */}
                <div className="col-md-7">
                    <form onSubmit={handleSubmit} className="contact-form">
                        <h3 className='form-title'>Contact Form</h3>
                        <div className="contact-form-row">
                            <div className="form-group">
                                <label htmlFor="name">Full Name</label>
                                <input
                                    type="text"
                                    id="name"
                                    className="full-name-input"
                                    placeholder="Full Name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="phone">Phone Number</label>
                                <PhoneInput
                                    international
                                    defaultCountry="JO"
                                    placeholder="790000000"
                                    value={mobile}
                                    onChange={setMobile}
                                    required
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="message">Message</label>
                            <textarea
                                id="message"
                                value={message}
                                placeholder="Your Message here"
                                onChange={(e) => setMessage(e.target.value)}
                                required
                            />
                        </div>
                        <button type="submit">Submit</button>
                    </form>
                </div>
                {/* Right Part - Cards */}
                <div className="col-md-5">
                    <div className="contact-card">
                        <img src={EmailIcon} alt="Phone Icon" />
                        <div className="card-text">
                            <span className="card-text-title">Phone Number</span>
                            <span className="card-text-value">{contactContent.mobile}</span>
                        </div>
                    </div>
                    <div className="contact-card">
                        <img src={PhoneIcon} alt="Email Icon" />
                        <div className="card-text">
                            <span className="card-text-title">Email</span>
                            <span className="card-text-value">{contactContent.email}</span>
                        </div>
                    </div>
                    <div className="contact-card">
                        <img src={LocationIcon} alt="Location Icon" />
                        <div className="card-text">
                            <span className="card-text-title">Location</span>
                            <span className="card-text-value">{contactContent.location}</span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ContactUsSection;
